import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {AlertService} from './alert.service';
import {GlobalService} from './global.service';
import {AppUrl} from '../constants/app-url';

@Injectable({
  providedIn: 'root'
})
export class IssuesService extends BaseService {
  constructor(public http: HttpClient,
              public alertService: AlertService,
              public globalService: GlobalService) {
    super(http, alertService, globalService);
  }

  create(data): Observable<any> {
    return this.postRequest(AppUrl.ISSUES(), data);
  }

  get(data): Observable<any> {
    return this.getRequest(AppUrl.ISSUES(), data);
  }

  detail(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUE_DETAILS(unique_id), data);
  }

  getCount(data): Observable<any> {
    return this.getRequest(AppUrl.ISSUE_COUNT(), data);
  }

  updateStatus(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUE_STATUS(unique_id), data);
  }

  updatePaymentStatus(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUE_PAYMENT_STATUS(unique_id), data);
  }

  updateEditStatus(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUE_EDIT_STATUS(unique_id), data);
  }

  updateCourierReceipt(unique_id, data): Observable<any> {
    return this.putRequest(AppUrl.ISSUE_COURIER_RECEIPT(unique_id), data);
  }
}
