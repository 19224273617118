<div class="container-fluid">
  <!--<div class="row" *ngFor="let ticket of tickets; index as i">
    <div class="col-sm-12 col-xl-12">
      <div class="card ribbon-wrapper shadow-0 border p-0">
        <div class="card-body notification-padding">
          <div class="ribbon ribbon-success ribbon-vertical-left"><i class="fa fa-bell" *ngIf="ticket.status === 'Resolved'"></i></div>
          <div class="ribbon ribbon-warning ribbon-vertical-left" *ngIf="ticket.status === 'In Process'"><i class="fa fa-bell"></i></div>
          <div class="ribbon ribbon-secondary ribbon-vertical-left" *ngIf="ticket.status === 'Rejected'"><i class="fa fa-bell"></i></div>
          <h4>{{ticket?.subjects}}</h4>
          <h6>{{ticket?.notification}}</h6>
          <div class="m-r-50 m-t-20 span badge badge-pill pill-badge-primary cursor-pointer pull-left">{{ticket?.created_at}}</div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="row">
    <div class="col-sm-12 col-xl-12">
      <div class="card  shadow-0 border ">
        <div class="card-body">
          <div class="row p-50px">
            <div class="col-sm-12 col-xl-12 text-center">
              <h6 class="mt-15px">No data to show</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
