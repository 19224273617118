<div class="container-fluid">
  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" >Add Notification</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-sm-12">
          <form class="theme-form">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label class="col-form-label pt-0" for="type">Notification For</label>
                  <select class="form-control resize-none" id="type" type="status" cols="4" rows="4"  aria-describedby="nameHelp">
                    <option value=""> Select Type</option>
                    <option > Center</option>
                    <option > Student</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="col-form-label pt-0" for="detail">Notification</label>
                  <textarea class="form-control resize-none" id="detail" type="status" cols="4" rows="4"  aria-describedby="nameHelp" placeholder="Enter Notification"></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="modal-footer d-lg-block">
      <button class="btn btn-success mr-1 btn-pill pull-right" ><i class="fa fa-edit"></i> Submit</button>
      <button class="btn btn-danger mr-1 btn-pill pull-left" (click)="modal.dismiss('Cross click')"><i class="fa fa-trash-o"></i> Cancel</button>
    </div>
  </ng-template>
  <div class="row m-b-50 P-0">
    <div class="col-12 P-0">
      <button class="btn btn-primary pull-right" (click)="openLg(content)"><i class="fa fa-plus-circle"></i> Add Notification</button>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <!--Use class<code>.table-primary</code> inside thead tr element.-->
          <div class="row">
            <div class="col-12">
              <h5>List Notification Table</h5>
            </div>
          </div>
        </div>
        <div class="card-block row">
          <div class="col-sm-12 col-lg-12 col-xl-12">
            <div class="table-responsive">
              <table class="table text-center">
                <thead class="thead-light .bg-*">
                <tr>
                  <th scope="col" class="text-center">S.No</th>
                  <th scope="col" class="text-center ">Notification</th>
                  <th scope="col" class="text-center ">Created At</th>
                  <th scope="col" class="text-center">Status </th>
                  <th scope="col" class="text-center">Action </th>
                </tr>
                </thead>
                <tbody>
                <!--<tr *ngFor="let followup of FollowUps;  index as i">
                  <td class="text-center">{{i + 1}}</td>
                  <td  class="text-center">{{followup?.name}}</td>
                  <td  class="text-center">{{followup?.created_at}}</td>
                  <td  class="text-center">{{followup?.status}}</td>
                  <td>

                    <i class="icofont icofont-edit mr-5px font-size-20 color-darkcyan" title="Edit"></i>
                    <i class="icofont icofont-lock font-size-20 color-danger" title="InActive"></i>

                  </td>
                </tr>-->
                <tr>
                  <td class="text-center" colspan="11">
                    <h6 class="mt-15px">No data to show</h6>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="pagination-top">
            <ngb-pagination class="d-flex justify-content-center" (pageChange)="onPageChange($event)"
                            [(page)]="pagination.page"
                            [boundaryLinks]="true"
                            [collectionSize]="pagination.total"
                            [maxSize]="5"
                            [pageSize]="pagination.perpage">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
