import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as feather from 'feather-icons';
import {NavService} from '../../../services/nav.service';
import {LayoutService} from '../../../services/layout.service';
import {fadeInAnimation} from '../../../data/router-animation/router-animation';
import {filter} from "rxjs/operators";
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from "@angular/router";
import {map} from "rxjs/internal/operators";
import {UniversityService} from "../../../services/university.service";
import {StorageService} from "../../../services/storage.service";
import {Helper} from "../../../constants/helper";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  animations: [fadeInAnimation]
})
export class ContentComponent implements OnInit, AfterViewInit {
  public right_side_bar: boolean;
  user: any;
  userData: any;

  constructor(public navServices: NavService,
              public layout: LayoutService,
              private activatedRoute: ActivatedRoute,
              private universityService: UniversityService,
              private router: Router) {
    this.user = StorageService.getItem('self');
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).pipe(map(() => this.activatedRoute)).pipe(map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    })).pipe(filter(route => route.outlet === PRIMARY_OUTLET)).subscribe(route => {
      const snapshot = this.router.routerState.snapshot;
      const title = route.snapshot.data.title;
      const parent = route.parent.snapshot.data.breadcrumb;
      const child = route.snapshot.data.breadcrumb;
      this.getUserDetail(route.snapshot.data);
    });
  }

  getUserDetail(routingData) {
    if (this.user && this.user.user_id) {
      this.universityService.detail(this.user.user_id).subscribe(data => {
        if (data) {
          this.userData = data;
          let subPermissionsArr = [];
          if (this.userData && this.userData['role'] && this.userData['role']['role_permissions'] && this.userData['role']['role_permissions'].length > 0) {
            this.userData['role']['role_permissions'].forEach(rolePermission => {
              subPermissionsArr.push(rolePermission['sub_permission']['meta']);
            });
            if (routingData && routingData['availableTo'] && routingData['availableTo'].length > 0) {
              if (!Helper.findCommonElements(routingData['availableTo'], subPermissionsArr) && !routingData['availableTo'].includes('ALL')) {
                this.router.navigateByUrl('general/home');
              }
            } else {
              this.router.navigateByUrl('general/home');
            }
          }
        }
      });
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      feather.replace();
    });
  }

  ngOnInit() {
    this.refresh();
  }

  refresh() {
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  public rightSidebar($event) {
    this.right_side_bar = $event;
  }
}

