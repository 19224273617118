import {Component, OnInit} from '@angular/core';
import {IssuesService} from '../../shared/services/issues.service';

@Component({
  selector: 'ps-issues',
  templateUrl: './issues.component.html',
  styleUrls: ['./issues.component.scss']
})
export class IssuesComponent implements OnInit {
  provisionaLIssue: any;
  markSheetIssue: any;
  transcriptIssue: any;
  revaluationIssue: any;
  migrationIssue: any;
  originalDegreeIssue: any;
  otherIssue: any;

  constructor(private issuesService: IssuesService) {
  }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.getProvisionalIssues();
    this.getMarksheetIssues();
    this.getTranscriptIssues();
    this.getRevaluationIssues();
    this.getMigrationIssues();
    this.getOriginalDegreeIssues();
    this.getOtherIssues();
  }

  getProvisionalIssues() {
    let params = {
      'type': 'PROVISIONAL_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.provisionaLIssue = data;
      }
    });
  }

  getMarksheetIssues() {
    let params = {
      'type': 'MARKSHEET_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.markSheetIssue = data;
      }
    });
  }

  getTranscriptIssues() {
    let params = {
      'type': 'TRANSCRIPT_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.transcriptIssue = data;
      }
    });
  }

  getRevaluationIssues() {
    let params = {
      'type': 'REVALUATION_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.revaluationIssue = data;
      }
    });
  }

  getMigrationIssues() {
    let params = {
      'type': 'MIGRATION_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.migrationIssue = data;
      }
    });
  }

  getOriginalDegreeIssues() {
    let params = {
      'type': 'ORIGINAL_DEGREE_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.originalDegreeIssue = data;
      }
    });
  }

  getOtherIssues() {
    let params = {
      'type': 'OTHER_ISSUES'
    };
    this.issuesService.getCount(params).subscribe(data => {
      if (data) {
        this.otherIssue = data;
      }
    });
  }
}
